<template>
  <div class="dea-with" v-if="showPage">
    <el-row>
      <el-page-header
        @back="goBack()"
        :content="lang === 'en' ? 'DeaWith Order' : '处理订单'"
      ></el-page-header>
    </el-row>
    <div class="order-content">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <!-- 基本信息 -->
        <h4>{{ $t('AddOrder.BasicInfo') }}</h4>
        <el-row :gutter="24" class="mt20">
          <el-col :span="6">
            <!-- 商城订单号 -->
            <el-form-item
              :label="$t('orderDetails.MallOrderNumber')"
              label-width="120px"
            >
              <el-input v-model="form.mallOrderCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :label="$t('orderDetails.OrderDate')"
              label-width="120px"
            >
              <el-input v-model="form.contractDate" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户订单号 :rules="[{ required: true, trigger: 'change', message: $t('placeholder.plsInput'),},]"-->
            <el-form-item
              :label="$t('AddOrder.CustomerPO')"
              label-width="120px"
              clearable
            >
              <el-input
                v-model="form.customerOrderCode"
                :placeholder="$t('placeholder.plsInput')"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出口抬头 -->
            <el-form-item
              :label="$t('AddOrder.ExportsRise')"
              label-width="120px"
            >
              <el-input v-model="form.exportsRise" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt15">
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item
              :label="$t('AddOrder.SalesRep')"
              label-width="120px"
              clearable
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <UserSelect
                v-if="showPage"
                :defaultUser="{
                  userId: form.businessId,
                  userName: form.businessName,
                  englishName: form.businessNameEn,
                }"
                @user-choose="chooseBusiness"
                ref="userselect"
              ></UserSelect>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!--订单来源  -->
            <el-form-item :label="$t('AddOrder.OrderFrom')" label-width="120px">
              <el-input v-model="form.orderSource" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <!-- 客户信息 -->
        <h4>{{ $t('AddOrder.CustomerInfo') }}</h4>
        <table class="custom-table">
          <thead>
            <tr>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 客户邮箱 -->
                  {{ $t('AddOrder.CustomerEmail') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 客户姓名 -->
                  {{ $t('AddOrder.CustomerName') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司名称 -->
                  {{ $t('AddOrder.CompanyName') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司地址 -->
                  {{ $t('companyAddress.CompanyDetailedAddress') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 联系人 -->
                  {{ $t('AddOrder.Contacts') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 联系电话 -->
                  {{ $t('AddOrder.ContactNumber') }}
                </span>
              </th>
              <th width="200" v-if="lang !== 'en'">
                <span>
                  <!-- 客户/公司备注 -->
                  客户/公司备注
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <el-form-item>
                  <el-input v-model="form.customerEmail" disabled></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item>
                  <el-input v-model="form.customerName" disabled></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item>
                  <el-input
                    v-model="form.companyName"
                    disabled
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <div class="company-name" v-if="showCompanyDetailedAddress">
                  <span>
                    {{ companyDetailedAddress }}
                  </span>
                  <i
                    v-if="showEditAddressBol"
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="addCompanyAddress"
                  ></i>
                </div>
              </td>
              <td>
                <el-form-item>
                  <el-input
                    v-model="form.customerName"
                    disabled
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item>
                  <el-input
                    v-model="form.customerPhone"
                    disabled
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </td>
              <td class="client-remark" v-if="lang !== 'en'">
                <div class="client-remark-wrap">
                  <span>客户备注：</span>
                  <p>{{ clientRemark ? clientRemark : '--' }}</p>
                  <el-button
                    type="text"
                    @click="showMoreRemark('1')"
                    v-if="clientRemark"
                  >
                    查看更多
                  </el-button>
                </div>
                <div class="client-remark-wrap">
                  <span>公司备注：</span>
                  <p>{{ companyRemark ? companyRemark : '--' }}</p>
                  <el-button
                    type="text"
                    @click="showMoreRemark('2')"
                    v-if="companyRemark"
                  >
                    查看更多
                  </el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-divider></el-divider>
        <!-- 产品列表 -->
        <div class="product-list">
          <div class="flex-btn mt20">
            <!-- 产品列表 -->
            <h4>{{ $t('AddOrder.ProductList') }}</h4>
          </div>
          <el-table
            class="no-space product-table mt15"
            :data="form.mallProductVOS"
            border
            :key="tablekey"
            max-height="580px"
            :header-cell-class-name="star"
          >
            <!-- 序号 -->
            <el-table-column
              align="center"
              width="55"
              :label="$t('AddOrder.No')"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <!-- 产品图 -->
            <el-table-column
              align="center"
              :width="lang === 'en' ? 140 : 120"
              :label="$t('AddOrder.ProductImage')"
              fixed="left"
            >
              <template slot-scope="scope">
                <div
                  class="tag-wrap"
                  :class="{ 'mark-box': scope.row.sampleFlag }"
                >
                  <el-tag size="mini">
                    {{ getLangProductType(scope.row.standardCustomized) }}
                  </el-tag>
                  <el-tag
                    size="mini"
                    class="el-tag-other"
                    v-if="scope.row.sampleFlag"
                  >
                    {{ $t('AddOrder.PrenatalSample') }}
                  </el-tag>
                  <el-image
                    v-if="scope.row.imgMain"
                    :src="scope.row.imgMain"
                    class="c_pointer"
                    @click="handlePreview(scope.row.imgMain)"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>
            <!-- 产品信息 -->
            <el-table-column
              align="center"
              width="240"
              :label="$t('AddOrder.ProductInfo')"
              fixed="left"
            >
              <template slot-scope="scope">
                <div class="text-sty">
                  <!-- 产品编码-产品名称-规格-产品运营 -->
                  <p>
                    {{ $t('orderDetails.ProductCode') }}：{{ scope.row.sku }}
                  </p>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.productEn"
                    placement="top-start"
                  >
                    <p>
                      {{ $t('orderDetails.ProductName') }}：{{
                        scope.row.productEn
                      }}
                    </p>
                  </el-tooltip>
                  <!-- 英文规格 -->
                  <p class="text-left">
                    {{ $t('addInquiry.EnglishSpecification') }}：{{
                      scope.row.specification == 'undefined'
                        ? '--'
                        : scope.row.specification || '--'
                    }}
                  </p>
                  <!-- 中文规格 -->
                  <p class="text-left">
                    {{ $t('addInquiry.CNSpecification') }}：{{
                      scope.row.specificationCn == 'undefined'
                        ? '--'
                        : scope.row.specificationCn || '--'
                    }}
                  </p>
                  <p>
                    {{ $t('orderDetails.ProductOperations') }}：{{
                      lang === 'en'
                        ? scope.row.productBelongerEn || '--'
                        : scope.row.productBelonger || '--'
                    }}
                  </p>
                </div>
              </template>
            </el-table-column>

            <!-- 库存 -->
            <el-table-column
              align="center"
              width="200"
              :label="$t('AddOrder.Stock')"
            >
              <template slot-scope="scope">
                <div v-if="lang === 'en'">
                  <div v-if="scope.row.warehouseNumberEn">
                    <p
                      v-for="(item, i) in scope.row.warehouseNumberEn.split(
                        ';'
                      )"
                      :key="i"
                    >
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>--</div>
                </div>
                <div v-else>
                  <div v-if="scope.row.warehouseNumber">
                    <p
                      v-for="(item, i) in scope.row.warehouseNumber.split(';')"
                      :key="i"
                    >
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>--</div>
                </div>
              </template>
            </el-table-column>
            <!-- 数量 -->
            <el-table-column
              align="center"
              prop="amount"
              width="120"
              :label="$t('AddOrder.Quantity')"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'mallProductVOS.' + [scope.$index] + '.amount'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: $t('rules.M2034'),
                      trigger: 'blur',
                    },
                    {
                      trigger: 'blur',
                      validator: (rule, value, callback) => {
                        const useAmount =
                          form.mallProductVOS[scope.$index].useAmount

                        if (!useAmount) {
                          callback()
                        } else {
                          if (value < useAmount) {
                            callback(
                              new Error(
                                lang === 'en'
                                  ? 'Quantity of products must >= Quantity of goods out/shipped'
                                  : '产品数量必须>=出库/出运数量'
                              )
                            )
                          } else {
                            callback()
                          }
                        }
                      },
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    disabled
                    v-model="form.mallProductVOS[scope.$index].amount"
                    @input="calculateTotal(scope.$index)"
                    @blur="
                      amountBlur(
                        form.mallProductVOS[scope.$index].amount,
                        form.mallProductVOS[scope.$index].productId,
                        form.mallProductVOS[scope.$index].sku,
                        scope.$index
                      )
                    "
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 售价 -->
            <el-table-column
              align="center"
              prop="netPrice"
              width="120"
              :label="$t('AddOrder.UnitPrice')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-model="form.mallProductVOS[scope.$index].netPrice"
                    disabled
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 印刷方式 -->
            <el-table-column
              align="center"
              width="150"
              :label="$t('AddOrder.ImprintMethod')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-select
                    v-if="
                      form.mallProductVOS &&
                      form.mallProductVOS[scope.$index].printingWaySelect &&
                      form.mallProductVOS[scope.$index].printingWaySelect.length
                    "
                    disabled
                    v-model="form.mallProductVOS[scope.$index].printingWayEn"
                    :placeholder="$t('placeholder.plsSel')"
                  >
                    <el-option
                      v-for="(item, index) in form.mallProductVOS[scope.$index]
                        .printingWaySelect"
                      :key="item.value + index"
                      :label="item.labelEn"
                      :value="item.labelEn"
                    ></el-option>
                  </el-select>
                  <el-row v-else>
                    <el-input
                      v-model="form.mallProductVOS[scope.$index].printingWayEn"
                      disabled
                    ></el-input>
                  </el-row>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 计价方式 -->
            <el-table-column
              align="center"
              width="150"
              :label="$t('productDetail.PricingMethod')"
            >
              <template slot-scope="{ row }">
                {{
                  (lang === 'en'
                    ? row.productExtend &&
                      row.productExtend.valuationMethodValueEn
                    : row.productExtend &&
                      row.productExtend.valuationMethodValue) || '--'
                }}
              </template>
            </el-table-column>
            <!-- 印刷位置 -->
            <el-table-column
              align="center"
              width="150"
              :label="$t('productDetail.PrintingPosition')"
            >
              <template slot-scope="{ row }">
                {{
                  (lang === 'en'
                    ? row.productExtend &&
                      row.productExtend.printingPositionValueEn
                    : row.productExtend &&
                      row.productExtend.printingPositionValue) || '--'
                }}
              </template>
            </el-table-column>
            <!-- 产品描述 -->
            <el-table-column
              align="center"
              prop="productDescription"
              :label="$t('AddOrder.ProductDescription')"
              width="200"
            >
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :rows="3"
                  v-model="form.mallProductVOS[scope.$index].productDescription"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </template>
            </el-table-column>
            <!-- 印刷描述 -->
            <el-table-column
              align="center"
              :label="$t('AddOrder.ImprintDescription')"
              width="200"
            >
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :rows="3"
                  v-model="form.mallProductVOS[scope.$index].printingEffect"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </template>
            </el-table-column>
            <!-- 计划装运日期 -->
            <el-table-column
              align="center"
              prop="planShippingDate"
              :label="$t('orderList.plannedShipmentDate')"
              width="200"
            >
              <template slot="header">
                <PlannedShipmentDate />
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'mallProductVOS.' +
                    [scope.$index] +
                    '.productExtend.planShippingDate'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <CalculatedDatePicker
                    v-model="
                      form.mallProductVOS[scope.$index].productExtend
                        .planShippingDate
                    "
                    type="date"
                    calc-type="planShippingDate"
                    :product-info="form.mallProductVOS[scope.$index]"
                    :placeholder="$t('placeholder.plsSel')"
                    :picker-options="{
                      disabledDate: (time) => {
                        return (
                          time >
                          new Date(
                            new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                          )
                        )
                      },
                    }"
                    clearable
                  ></CalculatedDatePicker>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 运输方式 -->
            <el-table-column
              align="center"
              prop="shippingMethod"
              :label="$t('orderDetails.ShippingMethod')"
              width="150"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'mallProductVOS.' +
                    [scope.$index] +
                    '.productExtend.shippingMethodId'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.productExtend.shippingMethodId"
                    :placeholder="$t('placeholder.plsSel')"
                    clearable
                    @change="
                      changeShipMethod(
                        scope.row,
                        scope.row.productExtend.shippingMethodId
                      )
                    "
                  >
                    <el-option
                      v-for="item in shippingMethodList"
                      :key="item.id"
                      :label="
                        lang === 'en'
                          ? item.transportWayEn
                          : item.transportWayCn
                      "
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 客户交期 -->
            <el-table-column
              align="center"
              prop="customerDeliveryDate"
              :label="$t('orderList.customerDeliveryTime')"
              width="200"
            >
              <template #header>
                <CustomerDeliveryTime />
              </template>
              <template slot-scope="scope">
                <el-form-item
                  class="special-form-item"
                  style="margin-top: 30px"
                  :prop="
                    'mallProductVOS.' +
                    [scope.$index] +
                    '.productExtend.customerDeliveryDate'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <CalculatedDatePicker
                    v-model="
                      form.mallProductVOS[scope.$index].productExtend
                        .customerDeliveryDate
                    "
                    :product-info="form.mallProductVOS[scope.$index]"
                    :address-list="addressList"
                    :quant-type="quantType"
                    type="date"
                    :placeholder="$t('placeholder.plsSel')"
                    :picker-options="{
                      disabledDate: (time) => {
                        return (
                          time >
                          new Date(
                            new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                          )
                        )
                      },
                    }"
                    @calc-delivery="(cb) => calcDelivery(scope.row, cb)"
                    clearable
                  ></CalculatedDatePicker>
                  <div class="text-left">
                    <el-checkbox
                      v-model="
                        form.mallProductVOS[scope.$index].productExtend.firmFlag
                      "
                      :true-label="1"
                      :false-label="0"
                    >
                      {{ lang === 'en' ? 'Firm date' : 'Firm 交期' }}
                    </el-checkbox>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- setUpCharge -->
            <el-table-column align="center" width="130" label="Setup Charge($)">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'mallProductVOS.' + [scope.$index] + '.setUpCharge'"
                >
                  <el-input
                    v-model="form.mallProductVOS[scope.$index].setUpCharge"
                    disabled
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 印刷文件 -->
            <el-table-column
              align="center"
              width="400"
              :label="$t('AddOrder.ArtFile')"
            >
              <template slot-scope="scope">
                <el-row>
                  <FileUpload
                    v-model="
                      form.mallProductVOS[scope.$index].printedDocumentsUrl
                    "
                  />
                </el-row>
              </template>
            </el-table-column>
            <!-- shippingFee -->
            <el-table-column align="center" width="150" label="Shipping Fee">
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-model="form.mallProductVOS[scope.$index].shippingFee"
                    disabled
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 其他费用 -->
            <el-table-column
              align="center"
              width="150"
              :label="$t('AddOrder.OtherFees')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-model="form.mallProductVOS[scope.$index].otherCharge"
                    disabled
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 费用说明 -->
            <el-table-column
              align="center"
              width="200"
              :label="$t('AddOrder.FeeExplanation')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :rows="3"
                    v-model="form.mallProductVOS[scope.$index].chargeFees"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 备注 -->
            <el-table-column
              align="center"
              prop="remark"
              :label="$t('AddOrder.Remark')"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :rows="3"
                    v-model="form.mallProductVOS[scope.$index].remark"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 客户折扣 -->
            <el-table-column
              align="center"
              width="160"
              :label="$t('AddOrder.CustomerDiscounts')"
            >
              <template slot-scope="scope">
                {{ scope.row.discountAmount || '--' }}
              </template>
            </el-table-column>
            <!-- 客户折扣说明 -->
            <el-table-column
              align="center"
              width="180"
              :label="$t('AddOrder.Discount')"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.discountReason">
                  {{
                    lang === 'en'
                      ? `discount code(${scope.row.discountReason})`
                      : `折扣码（${scope.row.discountReason}）`
                  }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- 效果图 -->
            <el-table-column
              align="center"
              prop="rendingsUrl"
              fixed="right"
              width="150"
              :label="$t('orderDetails.Renderings')"
            >
              <template slot-scope="scope">
                <div
                  class="wrapper-box"
                  @click="
                    choseRendingImages(
                      scope.$index,
                      scope.row.rendingsUrl,
                      scope.row.rendingsStatus
                    )
                  "
                >
                  <div class="tag-wrap" v-show="scope.row.rendingsUrl">
                    <el-tag size="mini">
                      {{ getRendingsStatusType(scope.row.rendingsStatus || 5) }}
                    </el-tag>
                    <img
                      :src="getFirstImgUrl(scope.row.rendingsUrl)"
                      width="80%"
                      alt
                    />
                  </div>
                  <div class="wrapper-add" v-show="!scope.row.rendingsUrl">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- 订单支持 -->
            <el-table-column
              align="center"
              :label="$t('AddOrder.OrderAssistant')"
              width="180"
              fixed="right"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'mallProductVOS.' + [scope.$index] + '.fromBusinessId'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]"
                >
                  <UserSelect
                    :ref="'userselct-' + scope.$index"
                    @user-choose="
                      (user) => chooseFromBusiness(user, scope.$index)
                    "
                    :defaultUser="{
                      userId:
                        form.mallProductVOS[scope.$index] &&
                        form.mallProductVOS[scope.$index].fromBusinessId,
                      userName:
                        form.mallProductVOS[scope.$index] &&
                        form.mallProductVOS[scope.$index].fromBusinessName,
                      englishName:
                        form.mallProductVOS[scope.$index] &&
                        form.mallProductVOS[scope.$index].fromBusinessNameEn,
                    }"
                  ></UserSelect>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 总计 -->
            <el-table-column
              align="center"
              :label="$t('AddOrder.Total')"
              width="150"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.usTotle">
                    ￥{{
                      parseFloat(scope.row.usTotle * exchangeRate).toFixed(2)
                    }}
                  </span>
                  <span v-else>￥0</span>
                </div>
                <div>
                  <span v-if="scope.row.usTotle">
                    ${{ parseFloat(scope.row.usTotle).toFixed(2) }}
                  </span>
                  <span v-else>$ 0</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 收货信息 -->
        <h4>{{ $t('AddOrder.ShippingInfo') }}</h4>
        <el-row
          :gutter="24"
          v-if="
            form.mallReceivingAddressVOS &&
            form.mallReceivingAddressVOS.length > 1
          "
        >
          <el-col :span="24">
            <div class="mt10 mb15">
              <!-- 货物分配方式 -->
              <el-form-item
                :label="$t('AddOrder.ModeOfCargoDistribution')"
                label-width
              >
                <el-radio v-model="quantType" :label="2">
                  <!-- 自行分配 -->
                  {{ $t('AddOrder.SelfDistribution') }}
                </el-radio>
                <!-- 绑定产品 -->
                <el-tooltip
                  effect="dark"
                  :content="$t('AddOrder.BindingProduct')"
                  placement="top"
                >
                  <i @click="bindProductShow" class="iconfont icon-fenpei"></i>
                </el-tooltip>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt15">
          <!-- 装运港 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.LoadingPort')"
              :label-width="lang === 'en' ? '140px' : '80px'"
              clearable
            >
              <el-input
                :maxlength="50"
                v-model="form.portOfLoading"
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目的港 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.DestinationPort')"
              :label-width="lang === 'en' ? '220px' : '120px'"
              clearable
            >
              <el-input
                :maxlength="50"
                v-model="form.destination"
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="c-table-wrap mt15">
          <table class="custom-table">
            <thead>
              <tr>
                <!-- 序号 -->
                <th class="index">{{ $t('AddOrder.No') }}</th>
                <!-- 收货地址 -->
                <th width="500">
                  <span>
                    <i>*</i>
                    {{ $t('AddOrder.ShippingAddress') }}
                  </span>
                </th>
                <!-- 收货公司 -->
                <th width="300">{{ $t('AddOrder.Company') }}</th>
                <!-- 收货人 -->
                <th width="200">{{ $t('AddOrder.Consignee') }}</th>
                <!-- 联系电话 -->
                <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
                <!-- 操作 -->
                <th class="operate">{{ $t('AddOrder.Operation') }}</th>
              </tr>
            </thead>
            <tbody
              v-if="
                form.mallReceivingAddressVOS &&
                form.mallReceivingAddressVOS.length
              "
            >
              <tr v-for="(item, i) in form.mallReceivingAddressVOS" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <el-form-item
                    :prop="'mallReceivingAddressVOS.' + i + '.address'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsSel'),
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-select
                      v-model="form.mallReceivingAddressVOS[i].address"
                      :placeholder="$t('placeholder.plsSel')"
                      @change="addressChange($event, i)"
                      clearable
                      :disabled="item.updateFlag === 0"
                      filterable
                      popper-class="address-popper"
                    >
                      <div
                        style="
                          display: flex;
                          font-size: 14px;
                          color: #606266;
                          background: #f5f7fa;
                          position: absolute;
                          left: 0;
                          top: 0;
                          z-index: 9999;
                        "
                      >
                        <span style="width: 300px; padding: 5px 8px">
                          Shipping Address
                        </span>
                        <span style="width: 80px; padding: 5px 8px">
                          Country
                        </span>
                        <span style="width: 140px; padding: 5px 8px">
                          Company
                        </span>
                        <span style="width: 120px; padding: 5px 8px">
                          Consignee
                        </span>
                        <span style="width: 140px; padding: 5px 8px">
                          Contact Phone
                        </span>
                      </div>
                      <el-option
                        v-for="item in receivingAddressConfigDTOS"
                        :key="item.addressConfigId"
                        :value="item.addressConfigId"
                        :disabled="item.disabled"
                        :label="item.address"
                      >
                        <template #default>
                          <div
                            style="
                              display: flex;
                              margin: 0 -20px;
                              color: #666;
                              line-height: 20px;
                            "
                          >
                            <span
                              style="
                                width: 300px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.address }}
                            </span>
                            <span
                              style="
                                width: 80px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.nation }}
                            </span>
                            <span
                              style="
                                width: 140px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.companyName }}
                            </span>
                            <span
                              style="
                                width: 120px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.consigneeName }}
                            </span>
                            <span
                              style="
                                width: 140px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.consigneePhone }}
                            </span>
                          </div>
                        </template>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td>{{ item.companyName }}</td>
                <td>{{ item.consigneeName }}</td>
                <td>{{ item.consigneePhone }}</td>
                <td>
                  <el-button
                    v-show="
                      form.mallReceivingAddressVOS &&
                      form.mallReceivingAddressVOS.length != 1
                    "
                    class="icon-r"
                    @click="delSelectAddress(i, item.deleteFlag)"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="empty">
                <!-- 暂无数据 -->
                <td colspan="6">{{ $t('other.noData') }}</td>
              </tr>
            </tbody>
          </table>
          <div class="address-btns">
            <!-- 添加一行 -->
            <span @click="addRow">{{ $t('AddOrder.AddMore') }}</span>
            <!-- 新增地址 -->
            <span @click="addAddress">{{ $t('AddOrder.CreateAddress') }}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 收款方式 -->
        <h4>{{ $t('AddOrder.CreateAddress') }}</h4>
        <el-row class="deduction">
          <el-col :span="6">
            <!-- 订单币种 -->
            <el-form-item
              :label="$t('AddOrder.Currency')"
              label-width="80px"
              class="mr20"
            >
              <el-select
                disabled
                v-model="form.currency"
                :placeholder="$t('placeholder.plsSel')"
              ></el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 收款方式 -->
            <el-form-item
              prop="payId"
              :label="$t('AddOrder.PaymentTerms')"
              label-width="125px"
            >
              <el-select
                v-model="payTemplateName"
                :placeholder="$t('placeholder.plsSel')"
                disabled
              ></el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <!-- 客户备注 -->
            <p class="mt20 mb10">{{ $t('AddOrder.CustomerRemark') }}</p>
            <el-input
              type="textarea"
              v-model="form.customerRemark"
              maxlength="500"
              show-word-limit
              :rows="3"
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-col>
          <el-col :span="12">
            <!-- 内部备注 -->
            <p class="mt20 mb10">{{ $t('AddOrder.InternalRemark') }}</p>
            <el-input
              type="textarea"
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              :rows="3"
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-col>
        </el-row>
        <!-- 附件 -->
        <p class="mt20 accessory">{{ $t('AddOrder.Attachment') }}</p>
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
          @initUpload="initUpload"
        ></ErpUpload>
      </el-form>
    </div>
    <div class="oper-btn">
      <!-- 确认 -->
      <el-button @click="submit()" type="success" :loading="submitLoading">
        {{ $t('AddOrder.GeneratePI') }}
      </el-button>
      <!-- 保存 -->
      <el-button type="primary" @click="save()">
        {{ $t('orderList.Save') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="goBack()">
        {{ $t('AddOrder.Cancel') }}
      </el-button>
    </div>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
    <!-- 选择印刷效果图 -->
    <choose-images
      :isEdit="isEdit"
      :visible.sync="chooseImagesDialogVisible"
      :default-file-list="currentDefaultRendingList"
      :default-rendings-status.sync="currentDefaultRendingStatus"
      @confirm="setRendingImages"
    ></choose-images>
    <!-- 新增收货地址 -->
    <addressAdd
      ref="address"
      :addressList="receivingAddressConfigDTOS"
      @addressConfigSave="addressConfigSave"
    ></addressAdd>
    <!-- 绑定产品 -->
    <el-dialog
      width="1200px"
      :title="$t('AddOrder.BindingProduct')"
      v-if="bindProductVisible"
      :visible.sync="bindProductVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="productForm" ref="productForm">
        <div v-for="(item, i) in form.mallReceivingAddressVOS" :key="i">
          <table class="custom-table no-space">
            <thead>
              <!-- 收货地址 -->
              <th>{{ $t('AddOrder.ShippingAddress') }}</th>
              <!-- 收货公司 -->
              <th>{{ $t('AddOrder.Company') }}</th>
              <!-- 收货人 -->
              <th width="200">{{ $t('AddOrder.Consignee') }}</th>
              <!-- 联系电话 -->
              <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <el-tooltip :content="item.address">
                    <div class="ell" style="width: 450px">
                      {{ item.address }}
                    </div>
                  </el-tooltip>
                </td>
                <td>
                  <el-tooltip :content="item.companyName">
                    <div class="ell" style="width: 300px">
                      {{ item.companyName }}
                    </div>
                  </el-tooltip>
                </td>
                <td>{{ item.consigneeName }}</td>
                <td>{{ item.consigneePhone }}</td>
              </tr>
            </tbody>
          </table>
          <table class="custom-table no-space mb10">
            <thead>
              <th width="600">Item</th>
              <!-- 订单数量 -->
              <th width="100">{{ $t('AddOrder.OrderQuantity') }}</th>
              <!-- 印刷方式 -->
              <th width="200">{{ $t('AddOrder.ImprintMethod') }}</th>
              <!-- 售价 -->
              <th width="100">{{ $t('AddOrder.UnitPrice') }}</th>
              <th width="100">
                <span>
                  <i>*</i>
                  <!-- 分配数量 -->
                  {{ $t('AddOrder.QuantityAllotted') }}
                </span>
              </th>
            </thead>
            <tbody>
              <tr v-for="(obj, k) in form.mallProductVOS" :key="k">
                <td>
                  <div>
                    <span v-show="obj.productCode">{{ obj.productCode }}</span>
                    <span v-show="obj.productEn">/{{ obj.productEn }}</span>
                    <span v-show="obj.specification">
                      /{{ obj.specification }}
                    </span>
                  </div>
                </td>
                <td>{{ obj.amount }}</td>
                <td>{{ obj.printingWayEn || obj.printingWay || '--' }}</td>
                <td>{{ obj.netPrice }}</td>
                <td>
                  <el-form-item
                    :prop="
                      'cargoList.' +
                      [k + i * form.mallProductVOS.length] +
                      '.quantTotle'
                    "
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                      {
                        pattern: /^[0-9]+?$/,
                        message: $t('placeholder.M2026'),
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          callback()
                        },
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      @input="verifyQuantTotle(i, k)"
                      v-model="
                        productForm.cargoList[
                          k + i * form.mallProductVOS.length
                        ].quantTotle
                      "
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form>
      <div class="text-center mt20">
        <!-- 确认 -->
        <el-button type="primary" @click="bindProduct">
          {{ $t('AddOrder.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="bindProductCancel">
          {{ $t('AddOrder.Cancel') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 新增客户地址 -->
    <CompanyAddress ref="CompanyAddress" @submitFn="submitFn" />
    <!-- dinero 订单重复弹框 -->
    <repeatOrdersModel
      :repeatOrdersBol.sync="repeatOrdersVisible"
      :gridData="repeatOrdersList"
      @confirmFn="confirmFn"
      @cancelFn="cancelFn"
    />
    <!-- 客户备注弹窗 -->
    <el-drawer
      :visible.sync="showMoreRemarkBol"
      :size="420"
      :with-header="false"
    >
      <div style="padding: 0 20px" class="remark-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="客户备注" name="1">
            <div v-if="clientRemarkList.length">
              <div v-for="(item, index) in clientRemarkList" :key="item.infoId">
                <div style="padding: 32px 0">
                  <p
                    style="
                      color: #999;
                      margin-bottom: 16px;
                      word-break: break-all;
                      line-height: 14px;
                    "
                  >
                    {{ item.creator }}&nbsp;&nbsp;{{ item.updateTime }}
                  </p>
                  <p style="color: #1a1a1a; word-break: break-all">
                    {{ item.remark }}
                  </p>
                </div>
                <el-divider
                  v-if="index != clientRemarkList.length - 1"
                ></el-divider>
              </div>
            </div>
            <div v-else style="text-align: center; padding-top: 47px">
              无备注
            </div>
          </el-tab-pane>
          <el-tab-pane label="公司备注" name="2">
            <div v-if="companyRemarkList.length">
              <div
                v-for="(item, index) in companyRemarkList"
                :key="item.infoId"
              >
                <div style="padding: 32px 0">
                  <p
                    style="
                      color: #999;
                      margin-bottom: 16px;
                      word-break: break-all;
                      line-height: 14px;
                    "
                  >
                    {{ item.creator }}&nbsp;&nbsp;{{ item.updateTime }}
                  </p>
                  <p style="color: #1a1a1a; word-break: break-all">
                    {{ item.remark }}
                  </p>
                </div>
                <el-divider
                  v-if="index != companyRemarkList.length - 1"
                ></el-divider>
              </div>
            </div>
            <div v-else style="text-align: center; padding-top: 47px">
              无备注
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import UserSelect from '@/components/user-select'
  import FileUpload from '@/components/FileUpload'
  import ChooseImages from './components/chooseImages.vue'
  import CompanyAddress from '@/components/CustomerAddress'
  import addressAdd from '@/views/order/orderList/components/addressAdd'
  import CalculatedDatePicker from '@/components/CalculatedDatePicker'
  import {
    PlannedShipmentDate,
    CustomerDeliveryTime,
  } from '@/components/shipping'
  import { getDetailForEditShow } from '@/api/client-supplier/client-manage'
  import { getStepByProductId } from '@/api/stock-center/shipment'
  import {
    orderGetDict,
    orderShipType,
    listData,
    samePoCheck,
  } from '@/api/order'
  import {
    MallOrderInteractor,
    OrderListInteractor,
    clientOrderInstance,
  } from '@/core'
  import repeatOrdersModel from '@/views/order/orderList/components/repeatOrders'
  export default {
    name: 'DeaWith',
    data() {
      return {
        showPage: false,
        quantType: 2,
        receivingAddressConfigDTOS: [], //收货地址下拉框
        repeatOrdersVisible: false, // dinero 订单重复弹框显隐
        repeatOrdersList: [], // dinero 订单重复弹框数据
        addTemplate: {
          address: '', //收货地址
          companyName: '', //收货公司
          consigneeName: '', //收货人
          consigneePhone: '', //联系电话
        },
        form: {
          exportsRise: '', //出口抬头
          exportsRiseId: '', //出口抬头id
          orderSource: '官网商城', //订单来源
          orderSourceId: '', //订单来源id
          mallProductVOS: [],
          shippingMethodId: '', //运货方式
          shippingMethod: '',
          shippingMethodType: '',
          shippingDate: '', //装运日期
          deliveryDate: '', //交货日期
          portOfLoading: '', //装运港
          destination: '', //目的港
          currency: '美元', //币种
          payId: '', //收款方式
          amountInterval: '', //收款方式截取
          paymentProportion: '', //收款方式截取
          customerRemark: '', //客户备注
          remark: '', //内部备注
          accessoryUrl: '', //附件
          cargoDistributionDTOS: [], //分配集合

          companyCountry: '', //国家
          companyProvince: '', //州
          companyCity: '', //市
          companyPostcode: '', //邮编
          companyAddress: '', //公司地址
        },
        bindProductVisible: false,
        productForm: {
          cargoList: [],
        },
        payTemplateName: 'ALL/100%deposit',
        tablekey: 1,
        showViewer: false, //图片预览参数
        rules: {
          usMoney: [
            {
              required: false,
              message: '抵扣金额格式不正确',
              pattern: /^\d+(\.\d{1,3})?$/,
              trigger: 'change',
            },
          ],
        },
        chooseImagesDialogVisible: false,
        currentRendingIndex: 0,
        currentDefaultRendingStatus: '',
        currentDefaultRendingList: [],
        isEdit: false,
        shippingMethodList: [], //货运方式集合
        exchangeRate: 1, // 汇率
        currencyList: [],
        moneyAreaList: [], //收款方式
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        submitLoading: false,
        computedCompanyDetailedAddress: '',
        countryOptions: [],
        showEditAddressBol: true,
        showCompanyDetailedAddress: false,
        companyRemarkList: [], //公司备注
        clientRemarkList: [], //客户备注
        showMoreRemarkBol: false, //客户备注弹窗
        activeName: '1', // 客户备注tab
        paramsData: {}, //订单重复所需参数
        addressList: [], // 地址列表
      }
    },
    mounted() {
      const query = this.$route.query
      if (query.id) {
        this.getOrderDetails(query.id)
      }
      this.getDict()
    },
    methods: {
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.form.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.form.companyAddress
          this.showCompanyDetailedAddress = true
          return
        }
        this.getStateFn(countryObj.id)
          .then((data) => {
            let ProvinceObj = data.filter(
              (item) => item.code === this.form.companyProvince
            )?.[0]
            let companyCountry = countryObj.name
            let companyProvince = ProvinceObj?.name
            let addressArr = [
              companyCountry,
              companyProvince || this.form.companyProvince,
              this.form.companyCity,
              this.form.companyAddress,
            ]
            this.computedCompanyDetailedAddress = addressArr
              .filter((item) => item)
              .join('/')
            this.showCompanyDetailedAddress = true
          })
          .catch(() => {
            this.showCompanyDetailedAddress = true
          })
      },
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.countryOptions = res.data
        } else {
          this.countryOptions = []
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取订单详情
      async getOrderDetails(id) {
        await this.countryList()
        let res = await MallOrderInteractor.getMallOrderVOApi(id)
        if (res?.code === '000000') {
          for (let k in res.data) {
            this.$set(this.form, k, res.data[k])
          }
          this.getOrderGetDict()
          this.getDetailedAddress()
          // 获取备注
          let paramsClient = {
            infoId: res?.data?.infoId,
            email: res?.data?.customerEmail,
            remarkType: 2,
          }
          this.getRemark(paramsClient)
          let paramsCompany = {
            companyId: res?.data?.companyId ?? '',
            companyName: res?.data?.companyName ?? '',
            remarkType: 1,
          }
          this.getRemark(paramsCompany)
          if (this.form.companyCountry) {
            this.showEditAddressBol = false
          } else {
            this.showEditAddressBol = true
          }
        }
        // 获取收货地址
        this.getCustomMoneyArea(this.form.companyName)

        this.showPage = true
        setTimeout(() => {
          if (res?.data?.accessoryUrl) this.initUpload(res.data.accessoryUrl)
        }, 100)
      },
      // 获取出口抬头
      getOrderGetDict() {
        orderGetDict({ amountType: 1 }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            res.data.forEach((item) => {
              if (item.amountStatus == 1) {
                if (item.amountShort === this.form.exportsRise)
                  this.form.exportsRiseId = item.amountId
              }
            })
          }
        })
      },
      // 获取字典项
      getDict() {
        // 获取订单来源
        orderGetDict({ amountType: 3 }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            res.data.forEach((item) => {
              if (item.amountName === this.form.orderSource)
                this.form.orderSourceId = item.amountId
            })
          }
        })
        // 获取汇率
        this.getExchangeRate()
        // 获取货运方式
        orderShipType().then((res) => {
          this.shippingMethodList = res.data?.map((i) => {
            i.id = i.id + ''
            return i
          })
        })
        // 获取币种
        this.getCurrency()
        // 获取收款方式
        this.getPaymentList()
      },
      // 获取汇率
      async getExchangeRate() {
        this.exchangeRate = await this.Dicts.getExchange('美元')
      },
      // 选择客户代表
      chooseBusiness(user) {
        const form = this.form
        form.businessId = user ? user.userId : ''
        form.businessName = user ? user.userName : ''
        form.businessNameEn = user ? user.englishName : ''
      },
      chooseFromBusiness(user, index) {
        this.$set(
          this.form.mallProductVOS[index],
          'fromBusinessId',
          user ? user.userId : ''
        )
        this.$set(
          this.form.mallProductVOS[index],
          'fromBusinessName',
          user ? user.userName : ''
        )
        this.$set(
          this.form.mallProductVOS[index],
          'fromBusinessNameEn',
          user ? user.englishName : ''
        )
      },
      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        const propList = [
          'amount',
          'netPrice',
          'productDescription',
          'shippingMethod',
        ]
        if (propList.includes(column.property)) {
          return 'star'
        }
        // if (columnIndex == 5 || columnIndex == 6 || columnIndex == 8) {
        //   return 'star'
        // }
      },
      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },
      // 效果图状态展示
      getRendingsStatusType(state) {
        let text = ''
        switch (state * 1) {
          case 1:
            text = this.$t('orderList.HasBeenConfirmed')
            break
          case 2:
            text = this.$t('orderList.NoFile')
            break
          case 3:
            text = this.$t('orderList.CustomerConfirmation')
            break
          case 4:
            text = this.$t('orderList.BeingRevised')
            break
          case 5:
            text = this.$t('orderList.CustomerHasSubmitted')
            break
        }
        return text
      },
      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      // 取消预览
      closeViewer() {
        this.showViewer = false
      },
      // 显示 选择印刷效果图 弹窗
      choseRendingImages(index, urls, status) {
        this.chooseImagesDialogVisible = true
        this.currentRendingIndex = index
        this.currentDefaultRendingStatus = status || '3'
        this.currentDefaultRendingList = []
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
        }
      },
      // 设置对应产品的 印刷效果图
      setRendingImages(data) {
        this.form.mallProductVOS[this.currentRendingIndex].rendingsUrl =
          data.urls
        this.form.mallProductVOS[this.currentRendingIndex].rendingsStatus =
          data.status
      },
      //根据数量计算售价
      amountBlur(val, pro, sku, index) {
        getStepByProductId({
          num: Number(val),
          productId: pro,
          sku: sku,
        }).then((res) => {
          if (res && res.code === '000000') {
            if (res.data) {
              const data = res.data

              this.$set(
                this.form.mallProductVOS[index],
                'netPrice',
                data.netPrice
              )
              this.$set(
                this.form.mallProductVOS[index],
                'warehouseNumber',
                data.warehouseNumber
              )
              this.calculateTotal(index)
            }
          }
        })
      },
      // 计算totalPrice = amount*netPrice + setUpCharge + otherCharge + ShippingFee - discountAmount
      calculateTotal(i) {
        let products = this.form.mallProductVOS
        if (products[i].amount) {
          let amount = null
          amount =
            Number(products[i].amount * products[i].netPrice || 0) +
            Number(products[i].setUpCharge) +
            Number(products[i].otherCharge) +
            Number(products[i].shippingFee || 0) -
            Number(products[i].discountAmount || 0)
          products[i].usTotle = parseFloat(amount).toFixed(3)
        } else {
          products[i].usTotle = 0
        }

        this.getTotalMoney()
      },

      // 获取总数量，总金额，总折扣
      getTotalMoney() {
        let [totalNumAry, totalMoneyAry] = [[], []]
        this.form.mallProductVOS.forEach((item) => {
          totalNumAry.push(Number(item.amount))
          totalMoneyAry.push(Number(item.usTotle))
        })

        this.totalNum = totalNumAry.reduce((prev, cur) => {
          return prev + cur
        })
        this.totalMoney =
          parseFloat(
            totalMoneyAry.reduce((prev, cur) => {
              return prev + cur
            })
          ).toFixed(3) - Number(this.refundProductAmount)

        this.totalDiscount = this.utils.amountTotal(
          this.form.mallProductVOS,
          'discountAmount',
          3
        )
      },
      // 添加一行
      addRow() {
        this.form.mallReceivingAddressVOS.push(
          JSON.parse(JSON.stringify(this.addTemplate))
        )
      },

      // 新增地址
      addAddress() {
        this.$refs.address.addressAddVisible = true
      },
      // 选择地址
      addressChange(val, i) {
        let list = this.form.mallReceivingAddressVOS
        let array = []

        // 当前执行的是清空收货地址的操作
        if (!val) {
          this.$set(this.form.mallReceivingAddressVOS, i, {})
          return false
        }

        // 不能选择重复的收货地址
        if (list.length) {
          list.forEach((item) => array.push(item.addressConfigId))
          if (array.includes(val) && val != list[i].addressConfigId) {
            const msg =
              this.lang === 'en'
                ? 'Delivery address can not be selected twice!'
                : '收货地址不能重复选择！'
            this.$message.warning(msg)
            this.$set(this.form.mallReceivingAddressVOS, i, {})
            return false
          }
        }

        // 收货地址赋值
        this.receivingAddressConfigDTOS.find((item) => {
          if (item.addressConfigId === val) {
            this.$set(this.form.mallReceivingAddressVOS, i, {
              addressConfigId: val,
              ...item,
            })
          }
        })
      },
      //根据公司名称获取收货地址
      async getCustomMoneyArea(companyName) {
        await getDetailForEditShow({ companyName }).then((res) => {
          this.receivingAddressConfigDTOS = res.data?.addressConfigVOS || []
          this.form.mallReceivingAddressVOS?.map((item, index) => {
            if (!this.receivingAddressConfigDTOS.length) {
              item.addressConfigId = new Date().getTime() + index
              return
            }
            const target = this.receivingAddressConfigDTOS?.find(
              (addressItem) =>
                addressItem.address === item.address &&
                addressItem.companyName === item.companyName &&
                addressItem.consigneeName === item.consigneeName &&
                addressItem.consigneePhone === item.consigneePhone
            )
            if (target?.addressConfigId) {
              item.addressConfigId = target.addressConfigId
            } else {
              item.addressConfigId = new Date().getTime() + index + ''
            }
            return item
          })
        })
        this.getDistribute()
      },
      // 显示绑定产品
      bindProductShow() {
        let orderProduct = this.form.mallProductVOS
        // 至少添加一条产品
        if (!orderProduct.length) {
          this.$message.error(this.$t('other.addedProduct'))
          return false
        }
        // 产品数量不能为空
        if (orderProduct.some((item) => item.amount == '')) {
          const msg =
            this.lang === 'en'
              ? 'The number of products is empty and cannot be allocated by itself'
              : '产品数量为空不能自行分配'
          this.$message.warning(msg)
          return false
        }
        let cargoList = []
        this.form.mallReceivingAddressVOS.forEach((item, i) => {
          this.form.mallProductVOS.forEach((obj, k) => {
            console.log(obj)
            cargoList.push({
              id: obj.id,
              address: item.address,
              companyName: item.companyName,
              consigneeName: item.consigneeName,
              consigneePhone: item.consigneePhone,
              amount: obj.amount,
              productCode: obj.productCode,
              productEn: obj.productEn,
              productId: obj.productId,
              quantTotle: this.getQuantTotleOld(obj.id, item.addressConfigId),
              sku: obj.sku,
              specification: obj.specification,
              addressConfigId: item.addressConfigId,
              orderProductUniqueId: obj.orderProductUniqueId || '',
            })
          })
        })
        this.productForm.cargoList = cargoList
        this.bindProductVisible = true
      },
      // 获取分配结果
      getDistribute() {
        let cargoList = []
        this.form.mallReceivingAddressVOS.forEach((item, i) => {
          this.form.mallProductVOS.forEach((obj, k) => {
            cargoList.push({
              id: obj.id,
              address: item.address,
              companyName: item.companyName,
              consigneeName: item.consigneeName,
              consigneePhone: item.consigneePhone,
              amount: obj.amount,
              productCode: obj.productCode,
              productEn: obj.productEn,
              productId: obj.productId,
              quantTotle: this.getQuantTotle(item.id, obj.id),
              sku: obj.sku,
              specification: obj.specification,
              addressConfigId: item.addressConfigId,
              orderProductUniqueId: obj.orderProductUniqueId || '',
            })
          })
        })
        this.productForm.cargoList = cargoList
      },
      // 获取分配数量
      getQuantTotle(addressId, productId) {
        let quantTotle = 0
        for (let k of this.form.mallCargoDistributionVOS) {
          if (
            k['mallProductId'] === productId &&
            k['mallReceivingAddressId'] === addressId
          ) {
            quantTotle = k['quantTotle']
          }
        }
        return quantTotle
      },
      // 获取上次输入是分配数量
      getQuantTotleOld(id, addressConfigId) {
        let quantTotle = 0
        const arr = JSON.parse(JSON.stringify(this.productForm.cargoList))
        for (let k of arr) {
          if (k['id'] === id && k['addressConfigId'] === addressConfigId) {
            quantTotle = k['quantTotle']
          }
        }
        console.log(quantTotle)
        return quantTotle
      },
      //切换运输方式
      changeShipMethod(product, shippingMethodId) {
        if (!shippingMethodId) {
          product.productExtend.shippingMethodType = ''
          product.productExtend.shippingMethod = ''
          product.productExtend.shippingMethodId = ''
          return
        }
        this.shippingMethodList.forEach((item) => {
          if (shippingMethodId == item.id) {
            product.productExtend.shippingMethodType = item.transportType
            product.productExtend.shippingMethod = item.transportWayCn
          }
        })
      },
      // 配置收货地址保存
      addressConfigSave(val) {
        if (val.hasOwnProperty('index')) {
          this.receivingAddressConfigDTOS[val.index] = { ...val }
          delete this.receivingAddressConfigDTOS[val.index].index
        } else {
          this.receivingAddressConfigDTOS.push(val)
        }
      },
      // 获取币种
      async getCurrency() {
        this.currencyList = await this.Dicts.getCurrency()
      },
      // 获取金额区间下拉框
      getPaymentList() {
        const params = {
          payee: 1,
          paymentId: '',
        }
        clientOrderInstance.getListForSelectByOrder(params).then((res) => {
          for (let k of res?.data) {
            if (k['payway'] === this.payTemplateName) {
              this.form.payId = k['paymentId']
              this.form.amountInterval = k['payway'].split('/')[0]
              this.form.paymentProportion = k['payway'].split('/')[1]
            }
          }
        })
      },
      // 回显附件
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
        this.tablekey = Math.random()
      },
      // 获取上传文件地址
      uploadList(fileList) {
        this.form.accessoryUrl =
          fileList.length === 0 ? '' : JSON.stringify(fileList)
      },
      // 绑定产品
      bindProduct() {
        let cargoList = []
        let falg = false
        this.$refs.productForm.validate((valid) => {
          if (valid) {
            // 一个地址的多个货物，数量总和不能为0
            this.productForm.cargoList.forEach((item) =>
              cargoList.push(item.addressConfigId)
            )
            cargoList = Array.from(new Set(cargoList))
            cargoList.forEach((item) => {
              let amount = 0
              this.productForm.cargoList.forEach((obj) => {
                if (item == obj.addressConfigId) {
                  amount += obj.quantTotle
                }
              })
              if (amount == 0) falg = true
            })
            if (falg) {
              const msg =
                this.lang === 'en'
                  ? 'The total number of assigned addresses cannot be 0'
                  : '一个地址分配数量总和不能为0'

              this.$message.warning(msg)
              return false
            }
            // 分配数量累加等于订单数量
            let [amount, quantTotle, amountArr, quantTotleArr] = [0, 0, [], []]
            this.form.mallProductVOS.forEach((item) =>
              amountArr.push(Number(item.amount))
            )
            this.productForm.cargoList.forEach((item) =>
              quantTotleArr.push(Number(item.quantTotle))
            )
            amount = amountArr.reduce((p, v) => p + v)

            quantTotle = quantTotleArr.reduce((p, v) => p + v)
            if (amount != quantTotle) {
              const msg =
                this.lang === 'en'
                  ? 'The total quantity allocated must equal the order quantity!'
                  : '分配数量总数必须等于订单数量！'

              this.$message.warning(msg)
              return false
            }
            this.bindProductVisible = false
          }
        })
      },
      // 绑定产品取消
      bindProductCancel() {
        this.bindProductVisible = false
      },
      // 保存订单
      save() {
        this.form.cargoDistributionDTOS = [...this.productForm.cargoList]
        this.submitDataFn(this.form, true)
      },
      // 验证保存提交订单
      submit() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            if (!this.form.companyCountry) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (
              this.form.companyCountry === 'US' &&
              !this.form.companyProvince
            ) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (!this.form.mallReceivingAddressVOS.length) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Add at least one shipping address!'
                  : '至少添加一条收货地址！'
              )
              return false
            }
            if (!this.form.mallProductVOS.length) {
              this.$message.error(this.$t('other.addedProduct'))
              return false
            }

            // 校验日期
            if (!this.validateOrderProductDTOS()) {
              return
            }

            if (!this.validAllocation()) return false
            if (!this.verificationDiagramFn()) return false
            this.form.cargoDistributionDTOS = [...this.productForm.cargoList]
            this.submitDataFn(this.form)
          }
        })
      },
      // 验证是不是所有效果图都必填且是确认状态
      verificationDiagramFn() {
        const noReqiured = ['Sample', 'Blank']
        const msg =
          this.lang === 'en'
            ? 'The renderings have not been uploaded!'
            : '效果图未上传!'
        const msg2 =
          this.lang === 'en'
            ? 'Please confirm the renderings!'
            : '请确认效果图！'
        let bol = true
        for (let i = 0; i < this.form.mallProductVOS.length; i++) {
          if (
            !noReqiured.includes(this.form.mallProductVOS[i]['printingWayEn'])
          ) {
            if (!this.form.mallProductVOS[i]['rendingsUrl']) {
              this.$message.warning(msg)
              bol = false
              break
            }
            if (this.form.mallProductVOS[i]['rendingsStatus'] != '1') {
              this.$message.warning(msg2)
              bol = false
              break
            }
          }
        }
        return bol
      },

      checkDeliveryDateAddressList(callback) {
        if (!this.addressList?.length) {
          this.$message.warning(this.$t('orderList.atLeastOneAddress'))
        } else {
          setTimeout(() => {
            callback()
          }, 200)
        }
      },

      calcDelivery(product, callback) {
        if (this.validAllocation()) {
          const _mallReceivingAddressVOS = JSON.parse(
            JSON.stringify(this.form.mallReceivingAddressVOS)
          )
          const newCargoList = this.productForm.cargoList.filter((item) => {
            return (
              item.orderProductUniqueId === product.orderProductUniqueId &&
              item.productId === product.productId &&
              item.sku === product.sku
            )
          })
          _mallReceivingAddressVOS.forEach((item) => {
            newCargoList.forEach((s) => {
              if (s.addressConfigId === item.addressConfigId) {
                item.quantTotle = s.quantTotle
              }
            })
          })
          const addressList = []
          // 遍历cargoDistributionDTOS,根据addressConfigId从receivingAddressConfigDTOS获取匹配的address
          _mallReceivingAddressVOS.forEach((item) => {
            let target = this.receivingAddressConfigDTOS.find(
              (s) => s.addressConfigId === item.addressConfigId
            )
            if (target) {
              addressList.push({
                quantTotle: item.quantTotle,
                city: target.city,
                country: target.nation,
                postcode: target.postcode,
                province: target.province,
                street: target.street,
                address: target.address,
              })
            }
          })
          this.addressList = addressList
          this.checkDeliveryDateAddressList(callback)
        }
      },
      //验证产品是否全部分配
      validAllocation() {
        let flag = 1
        // 地址超过1条，且是自行分配
        if (this.form.mallReceivingAddressVOS.length > 1) {
          // 获取已分配地址的ids
          const addrIds = this.productForm.cargoList
            .filter((item) => {
              return item.quantTotle
            })
            .map((item) => {
              return item.addressConfigId
            })
          const ids = Array.from(new Set(addrIds))

          const products = this.form.mallProductVOS

          for (let i = 0; i < this.form.mallProductVOS.length; i++) {
            const totalAmount = this.productForm.cargoList.reduce(
              (total, product) => {
                if (
                  products[i].productId == product.productId &&
                  products[i].sku == product.sku &&
                  products[i].orderProductUniqueId ==
                    product.orderProductUniqueId
                ) {
                  return (total += Number(product.quantTotle))
                } else {
                  return total
                }
              },
              0
            )

            //对比产品数量和分配数量，不相等即报错
            if (products[i].amount != totalAmount) {
              this.$message.warning(
                this.lang === 'en'
                  ? 'Product distribution is incorrect!'
                  : '产品分配不正确！'
              )
              flag = 0
              return false
            } else {
              flag = 1
            }
          }
          // 已分配地址长度小于所有地址长度，说明有地址未分配
          if (ids?.length != this.form.mallReceivingAddressVOS?.length) {
            const msg =
              this.lang === 'en'
                ? 'The total number of assigned addresses cannot be 0'
                : '一个地址分配数量总和不能为0'

            this.$message.warning(msg)
            flag = 0
          }
        } else {
          let cargoList = []
          this.form.mallReceivingAddressVOS.forEach((item, i) => {
            this.form.mallProductVOS.forEach((obj, k) => {
              cargoList.push({
                address: item.address,
                companyName: item.companyName,
                consigneeName: item.consigneeName,
                consigneePhone: item.consigneePhone,
                amount: obj.amount,
                productCode: obj.productCode,
                productEn: obj.productEn,
                productId: obj.productId,
                quantTotle: obj.amount,
                sku: obj.sku,
                specification: obj.specification,
                addressConfigId: item.addressConfigId,
                orderProductUniqueId: obj.orderProductUniqueId || '',
              })
            })
          })
          this.productForm.cargoList = cargoList
          flag = 1
        }
        return flag == 1 ? true : false
      },
      // 校验分配数量
      verifyQuantTotle(i, k) {
        let index = k + i * this.form.mallProductVOS.length // 当前产品的索引
        let amount = Number(this.form.mallProductVOS[k].amount) // 当前产品的总数
        let useAmount = Number(this.form.mallProductVOS[k].useAmount) // 当前产品已分配数量
        // 分配数量不能大于订单数量
        if (Number(this.productForm.cargoList[index].quantTotle) > amount) {
          const msg =
            this.lang === 'en'
              ? 'The assigned quantity cannot be greater than the order quantity!'
              : '分配数量不能大于订单数量！'

          this.productForm.cargoList[index].quantTotle = amount
          this.$message.warning(msg)
        }
        //旧地址判断 不能小于已分配数量
        if (
          this.form.mallReceivingAddressVOS[i].isOldAddress &&
          Number(this.productForm.cargoList[index].quantTotle) < useAmount
        ) {
          const msg =
            this.lang === 'en'
              ? 'The number of products at the original address must be ≥ the total number of products in the delivery order/delivery order!'
              : '原地址的产品数量必须≥出运委托单/出库单中该产品的总数！'

          this.productForm.cargoList[index].quantTotle = useAmount
          this.$message.warning(msg)
        }
      },
      //提交
      async submitDataFn(data, isSave) {
        let params = JSON.parse(JSON.stringify(data))
        params.orderProductDTOS = params.mallProductVOS
        params.orderProductDTOS.map((item) => {
          item.rmbTotle = parseFloat(item.usTotle * this.exchangeRate).toFixed(
            3
          )
          return item
        })
        params.receivingAddressDTOS = params.mallReceivingAddressVOS
        // 保存时去除空地址
        params.receivingAddressDTOS = params.receivingAddressDTOS.filter(
          (addressInfo) => addressInfo.address
        )
        params.linkman = params.customerName
        params.mallOrderId = params.id
        delete params.mallProductVOS
        delete params.mallReceivingAddressVOS
        delete params.mallCargoDistributionVOS
        // console.log('params', JSON.parse(JSON.stringify(params)))
        // return
        if (isSave) {
          let res = await MallOrderInteractor.saveMallOrderApi(params)
          if (res?.code === '000000') {
            this.$message.success('SUCCESS')
            setTimeout(() => {
              this.$router.push({
                path: '/order/mallOrder/index',
              })
            }, 500)
          }
        } else {
          this.checkOrderRepeat(params)
        }
      },
      validateOrderProductDTOS() {
        const { mallProductVOS } = this.form

        for (let i = 0; i < mallProductVOS.length; i++) {
          const item = mallProductVOS[i]

          // 假设 planShippingDate 和 customerDeliveryDate 都是日期字符串，进行比较前需要转换为日期对象
          const planShippingDate = new Date(item.productExtend.planShippingDate)
          const customerDeliveryDate = new Date(
            item.productExtend.customerDeliveryDate
          )

          if (planShippingDate > customerDeliveryDate) {
            // 提示信息
            // this.$message.warning(`第 ${i + 1} 行产品的计划发货日期不能晚于客户交货日期`);
            this.$message.warning(
              this.lang === 'en'
                ? `The plan shipping date of the ${
                    i + 1
                  }th product cannot be later than the customer delivery date.`
                : `第 ${i + 1} 行产品的计划发货日期不能晚于客户交货日期。`
            )

            return false // 返回 false
          }
        }

        // 如果所有项都符合条件，返回 true
        return true
      },
      // 确定提交
      async confirmSubmissionFn(params) {
        let res = await MallOrderInteractor.insertOrderToMallApi(params)
        this.submitLoading = false
        if (res?.code === '000000') {
          if (res?.data) {
            this.$message.success(res.data)
          } else {
            this.$message.success('SUCCESS')
          }
          setTimeout(() => {
            this.$router.push({
              path: '/order/orderList/orderList',
            })
          }, 500)
        }
      },
      // 校验订单是否有重复
      async checkOrderRepeat(params) {
        this.submitLoading = true
        this.paramsData = params
        let res = await samePoCheck(params)
        if (res?.code === '000000') {
          if (res?.data?.length) {
            this.repeatOrdersList = res.data
            this.repeatOrdersVisible = true
          } else {
            this.confirmSubmissionFn(params)
          }
        } else {
          this.submitLoading = false
        }
      },
      // 订单重复弹窗确认
      confirmFn() {
        this.repeatOrdersVisible = false
        this.confirmSubmissionFn(this.paramsData)
      },
      // 订单重复弹窗取消
      cancelFn() {
        this.submitLoading = false
        this.repeatOrdersVisible = false
      },
      // 删除选中地址 deleteFlag:0不能删除
      delSelectAddress(i, deleteFlag) {
        if (deleteFlag === 0) {
          const msg =
            this.lang === 'en'
              ? 'The address of the delivery order or warehouse order that has been generated cannot be deleted'
              : '已经生成出运委托单/出库单的地址不可以删除'

          return this.$message.warning(msg)
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            // 清空货物分配信息
            this.form.cargoDistributionDTOS = []
            this.productForm.cargoList = []
            this.form.mallReceivingAddressVOS.splice(i, 1)
            this.$message.success(this.$t('reqmsg.M2008'))
          })
          .catch(() => {})
      },
      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
      // 新增客户地址
      addCompanyAddress() {
        this.$refs.CompanyAddress.showCustomerAddress(
          this.form,
          this.countryOptions
        )
      },
      // 点击新增地址
      submitFn(obj) {
        let {
          companyCountry,
          companyProvince,
          companyCity,
          companyAddress,
          companyPostcode,
        } = obj
        this.form.companyAddress = companyAddress ?? ''
        this.form.companyCountry = companyCountry ?? '' //国家
        this.form.companyProvince = companyProvince ?? '' //州
        this.form.companyCity = companyCity ?? '' //市
        this.form.companyPostcode = companyPostcode ?? '' //邮编
        this.getDetailedAddress()
        this.showEditAddressBol = false
      },
      //更多备注切换
      showMoreRemark(val) {
        this.activeName = val
        this.showMoreRemarkBol = !this.showMoreRemarkBol
      },
      // 获取备注
      async getRemark(info) {
        let param = {
          companyId: '',
          infoId: '',
          remarkType: 1,
          email: '',
          companyName: '',
        }
        param = Object.assign(param, info)
        let res = await listData(param)
        if (res?.code === '000000') {
          if (param.remarkType === 1) {
            this.companyRemarkList = res?.data ?? []
          } else {
            this.clientRemarkList = res?.data ?? []
          }
        }
      },
    },
    watch: {
      lang() {
        this.getDetailedAddress()
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),
      companyDetailedAddress() {
        return this.computedCompanyDetailedAddress
      },
      // 获取客户备注信息
      clientRemark() {
        return this.clientRemarkList?.[0]?.remark ?? ''
      },
      // 获取公司备注信息
      companyRemark() {
        return this.companyRemarkList?.[0]?.remark ?? ''
      },
    },
    components: {
      UserSelect,
      repeatOrdersModel,
      FileUpload,
      ChooseImages,
      addressAdd,
      CompanyAddress,
      CalculatedDatePicker, // 计算日期组件
      PlannedShipmentDate, // 计划装运日期表头组件
      CustomerDeliveryTime, // 客户交货时间表头组件
    },
  }
</script>
<style scoped lang="scss">
  .dea-with {
    padding: 0 10px;
    height: calc(100vh - 45px - 0px - 9px * 2);
    overflow-y: auto;

    .disable-color {
      color: #c0c4cc;
    }

    .order-content {
      padding: 0 10px 0px;

      .el-divider {
        margin: 20px 0;
        background-color: #ececec;
      }

      .text-sty {
        text-align: left;

        p {
          color: #606266;
          line-height: 20px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .product-list {
        .product-table {
          /deep/ {
            .cell {
              overflow: initial;

              .el-form-item__content {
                padding: 5px 0;

                .el-form-item__error {
                  top: 42px;
                }
              }
            }
            .special-form-item .el-form-item__error {
              top: 60px !important;
            }
          }
        }

        .total-money {
          line-height: 40px;
          text-align: right;

          span {
            font-family: 'PingFang Bold';
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }

      .icon-fenpei {
        font-size: 24px;
        color: #409eff;
        vertical-align: middle;
        cursor: pointer;
      }

      .accessory {
        position: relative;
        padding-left: 10px;
      }

      .tag-wrap {
        position: relative;
        display: flex;
        justify-content: center;

        .el-tag {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 2;

          &.el-tag-other {
            background-color: #fff7e8;
            border-color: #ffefd1;
            color: #ffae18;
          }
        }

        .el-image {
          width: 64px !important;
          height: 64px !important;
        }
      }
    }

    .address-btns {
      margin: 10px 0 20px;
      text-align: center;

      span {
        margin: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }

    h4 {
      margin: 20px 0 10px;
      font-family: 'PingFang-Bold';
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
    }

    .bind-btn {
      width: 28px;
      height: 28px;
      text-align: center;

      i {
        font-size: 12px;
      }
    }
    .client-remark {
      .client-remark-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #515a6e;
        span {
          width: 70px;
          flex-shrink: 0;
        }
        p {
          width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
        .el-button--text {
          text-decoration: underline;
        }
      }
    }
    .remark-container {
      .el-divider {
        margin: 0 !important;
      }
    }
  }
</style>
<style>
  .address-popper .el-select-dropdown__item {
    height: auto !important;
  }
  .address-popper .el-select-dropdown__list {
    padding-top: 31px;
  }
</style>
